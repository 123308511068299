







































































































































import { FormValidations } from "@/mixins/form-validations";
import { Notification } from "@/models/notification.interface";
import { Penalty, PenaltyUser } from "@/models/penalty.interface";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { StyleCheck } from "@/mixins/style-check";
import { User } from "@/models/user.interface";
import moment from "moment";
import { CustomDate } from "@/mixins/custom-date";

@Component({})
export default class PenaltyForm extends mixins(
  FormValidations,
  StyleCheck,
  CustomDate
) {
  @Prop() dialog = false;
  @Prop() editPenalty!: PenaltyUser;

  defaultPenalty: PenaltyUser = {
    id: undefined,
    penalty_reason: "",
    start_date: "",
    fk_penalty: NaN,
    fk_client: undefined,
  };
  penalty: PenaltyUser = { ...this.defaultPenalty };

  $refs!: {
    penaltyForm: HTMLFormElement;
  };
  loading = false;
  userSearch = "";
  @Watch("editPenalty")
  private loadPenalty() {
    this.penalty = this.editPenalty;
  }

  private get formTitle() {
    return !this.penalty.id
      ? this.$tc("Penalties.createTitle")
      : this.$tc("Penalties.editTitle");
  }
  get is_edit() {
    return this.penalty.id;
  }
  get penalty_types(): Penalty[] {
    return this.$store.getters["penalties/getPenaltyTypes"];
  }
  get penalty_select_types() {
    const types: { text: string; value: number }[] = [];

    for (const pen of this.penalty_types) {
      (!this.selected_client ||
        (this.selected_client.penalty_grade as number) < pen.grade) &&
        types.push({
          text: `${pen.name} (${this.$tc("Penalties.headers.grade")} ${
            pen.grade
          })`,
          value: pen.id,
          ...pen,
        });
    }
    return types;
  }
  private get selected_client() {
    return this.users.find((user) => user.id == this.penalty.fk_client);
  }
  get expiration_date() {
    const penalty = this.penalty_types.find(
      (pen) => pen.id == this.penalty.fk_penalty
    );
    return (
      penalty &&
      penalty.duration_amount &&
      this.formatedDate(
        moment()
          .add(
            penalty.duration_amount,
            penalty.duration_type as moment.unitOfTime.DurationConstructor
          )
          .format()
      )
    );
  }
  private close(newPenalty?: PenaltyUser) {
    this.resetFormValidations([this.$refs.penaltyForm]);
    this.penalty = Object.assign(this.penalty, this.defaultPenalty);
    this.$emit("closeDialog", newPenalty);
  }

  private async createPenalty() {
    this.loading = true;
    if (this.$refs.penaltyForm.validate()) {
      await this.$store
        .dispatch("penalties/createPenalty", this.penalty)
        .then((newPenalty) => {
          const Success: Notification = {
            message: this.$tc("Penalties.success"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: false,
          };

          this.$store.dispatch("notifications/showNotification", Success);
          this.loading = false;
          this.close(newPenalty);
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Penalties.error"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);

          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  private async updatePenalty() {
    this.loading = true;
    if (this.$refs.penaltyForm.validate()) {
      await this.$store
        .dispatch("penalties/updatePenalty", {
          penalty_reason: this.penalty.penalty_reason,
          id: this.penalty.id,
        })
        .then(async () => {
          const Success: Notification = {
            message: this.$tc("Penalties.success"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: false,
          };

          this.$store.dispatch("notifications/showNotification", Success);
          this.loading = false;
          this.close();
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Penalties.error"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);

          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  userChange(user: any) {
    Object.assign(this.penalty, this.defaultPenalty);
    if (user) this.penalty.fk_client = user.id;
  }

  customFilter(item, queryText: string) {
    let text =
      item.id?.toLowerCase() +
      item.email?.toLowerCase() +
      item.phone.toLowerCase() +
      item.alias.toLowerCase() +
      item.identification.toLowerCase();
    if (item.first_name) text += item.first_name.toLowerCase();
    if (item.second_name) text += item.second_name.toLowerCase();
    if (item.last_name) text += item.last_name.toLowerCase();
    if (item.second_last_name) text += item.second_last_name.toLowerCase();

    const searchText = queryText.toLowerCase().replaceAll(/\s/g, "");

    return text.indexOf(searchText) > -1;
  }

  @Watch("userSearch")
  private userSearchHandler(value: string) {
    if (value == null) return;

    if (this.loading) return;

    this.loading = true;

    this.$store
      .dispatch("penalties/getClientsForPenalties", {
        text: value,
        status: [
          this.$constants.STATUS.ACTIVE,
          this.$constants.STATUS.PENALIZED,
        ],
      })
      .finally(() => {
        this.loading = false;
      });
  }

  get users(): User[] {
    return this.$store.getters["penalties/getClientsForPenalties"];
  }
}
